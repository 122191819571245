<template>
  <div class="px-5" id="page-jobs">
    
    <h5 class="mb-6 ml-2">Körningar</h5>

    <b-card
      title=""
      class="mb-2"
      hide-footer
    >
      <JobTable 
        type="ALL"
        class="mb-8"
      />
    </b-card>

  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import JobTable from '@/view/pages/ml/jobs/JobTable.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'pages',
  mixins: [ toasts ],
  components: {
    JobTable
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  mounted() {
    
  },
  data() {
    return {
      
    };
  },
  watch: {
    
  },
  methods: {
    
  }
};
</script>
